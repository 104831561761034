import { SxProps, Typography, Theme } from '@mui/material';
import { ExtLinkNoUnderline } from './ExtLinkNoUnderline';

type BullyingSectionProps = {
  titleStyle?: SxProps<Theme>;
};

export function BullyingSection({ titleStyle }: BullyingSectionProps) {
  return (
    <div>
      <Typography variant="h5" align="left" sx={titleStyle}>
        Bullying. No Way!
      </Typography>

      <Typography paragraph>
        <ExtLinkNoUnderline href="https://bullyingnoway.gov.au">
          This Australian resource provides information and helpful ideas about bullying for parents and teenagers.
        </ExtLinkNoUnderline>
      </Typography>

      <Typography variant="h5" align="left" sx={titleStyle}>
        Cyber-bullying information
      </Typography>

      <Typography component="span" paragraph>
        Adapted from: Australian Government eSafety Commissioner. See information for parents{' '}
        <ExtLinkNoUnderline href="https://www.esafety.gov.au/parents">here.</ExtLinkNoUnderline>
        <br />
        <br />
        For more information on cyber-safety, check out:
        <ul>
          <li>
            <ExtLinkNoUnderline href="https://www.ideas.org.au/uploads/events/333/Parenting%20online.pdf">
              Parents guide to online safety
            </ExtLinkNoUnderline>
            .
          </li>
          <li>
            <ExtLinkNoUnderline href="https://www.childnet.com/parents-and-carers">
              Childnet international.
            </ExtLinkNoUnderline>
          </li>
        </ul>
      </Typography>

      <Typography variant="h5" align="left" sx={titleStyle}>
        <ExtLinkNoUnderline href="https://www.esafety.gov.au/diverse-groups/lgbtiq">
          LGBTQIA+ information
        </ExtLinkNoUnderline>
      </Typography>

      <Typography component="span" paragraph>
        Adapted from:{' '}
        <ExtLinkNoUnderline href="http://familieslikemine.beyondblue.org.au/#folio=56">
          beyondblue. (n.d.) <b>Families like mine.</b>
        </ExtLinkNoUnderline>
        <br />
        <br />
        <Typography paragraph>
          Other resources to support LGBTQIA+ young people:
          <br />
        </Typography>
        <ul>
          <li>
            <ExtLinkNoUnderline href="https://headspace.org.au/friends-and-family/understanding-gender-identity-for-families/">
              headspace resource: Understanding gender identity – for family and friends.
            </ExtLinkNoUnderline>
          </li>
          <li>
            <ExtLinkNoUnderline href="https://headspace.org.au/friends-and-family/understanding-sexuality-and-sexual-identity-for-family-and-friends/">
              headspace resource: Understanding sexuality and sexual identity – for family and friends.
            </ExtLinkNoUnderline>
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" align="left" sx={titleStyle}>
        Helping your teenager navigate problems with social rejection and bullying
      </Typography>

      <Typography component="span" paragraph>
        Reference:
        <ol>
          <li>
            <ExtLinkNoUnderline href="https://www.dese.gov.au/student-resilience-and-wellbeing/resources/australian-covert-bullying-prevalence-study-executive-summary">
              Cross, D., Shaw, T., Hearn, L., Epstein, M., Monks, H., Lester, L., & Thomas, L. (2009).{' '}
              <b>Australian Covert Bullying Prevalence Study (ACBPS).</b> Edith Cowan University, Perth: Child Health
              Promotion Research Centre.
            </ExtLinkNoUnderline>
          </li>
        </ol>
      </Typography>
    </div>
  );
}
