import { SitePage } from 'app/layout';
import { Appendix } from 'app/appendix';
import { robotsNoIndex } from 'app/helpers';

function AppendixPage() {
  return (
    <SitePage>
      <Appendix />
    </SitePage>
  );
}

export default robotsNoIndex(AppendixPage);
