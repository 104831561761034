import { useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Heading } from 'components';
import { ReactNode } from 'react';
import { ContentAppendix, ContentAppendixProps } from './ContentAppendix';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

type TopicAppendixProps = {
  title: string;
  summary?: ReactNode;
  contents: Omit<ContentAppendixProps, 'firstReferenceNumber'>[];
};

export function TopicAppendix(props: TopicAppendixProps) {
  const { title, summary, contents } = props;

  const theme = useTheme();

  const headingStyle = {
    fontWeight: 500,
    color: 'darkPrimary.main',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      lineHeight: 1,
    },
  };

  // Calculate the first reference number for a given topic appendix
  const firstReferenceNumbers = contents.reduce(
    (acc, _content, index, contents) => [
      ...acc,
      (contents[index - 1]?.references?.length ?? 0) + (acc[index - 1] ?? 1),
    ],
    [] as number[],
  );

  return (
    <Accordion
      elevation={0}
      sx={{
        border: 'none',
        backgroundColor: 'transparent',
        '&:before': {
          backgroundColor: 'transparent',
        },
      }}>
      <AccordionSummary
        sx={{
          padding: 0,
          margin: 0,
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="crisis-support"
        id="crisis-support-headers">
        <Heading sx={headingStyle}>{title}</Heading>{' '}
      </AccordionSummary>

      <AccordionDetails sx={{ padding: 0 }}>
        {summary}
        {contents.map((content, index) => (
          <ContentAppendix firstReferenceNumber={firstReferenceNumbers[index]} {...content} key={index} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
