import { Box, Container, Typography, useTheme } from '@mui/material';
import { BullyingSection } from './BullyingSection';
import { TopicAppendix } from './TopicAppendix';
import { ExtLinkNoUnderline } from './ExtLinkNoUnderline';

export function Appendix() {
  const theme = useTheme();

  const titleStyle = {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  };

  return (
    <Container>
      <Typography color="darkPrimary.main" fontWeight="bold" variant="h5" align="left" sx={titleStyle}>
        Appendices
      </Typography>
      <Box mb={8}>
        <Typography paragraph>
          Here is a list of references and resources referred to in the PiP program. Where available, we have provided
          direct links. Click the module titles below to see the resources for each module.
        </Typography>
        <TopicAppendix
          title="Understanding anxiety and depression"
          summary={
            <>
              Click the following links to access the Mental Health First Aid Guidelines for{' '}
              <ExtLinkNoUnderline href="https://mhfa.com.au/sites/default/files/depression_-_mhfa_guidelines_2021_0.pdf">
                Depression
              </ExtLinkNoUnderline>
              ,{' '}
              <ExtLinkNoUnderline href="https://mhfa.com.au/sites/default/files/traumatic_events_children_-_mhfa_guidelines_2021_0.pdf">
                Trauma
              </ExtLinkNoUnderline>{' '}
              and{' '}
              <ExtLinkNoUnderline href="https://mhfa.com.au/sites/default/files/panic_attacks_-_mhfa_-_guidelines_-_2021_.pdf">
                Panic Attacks
              </ExtLinkNoUnderline>
              .
            </>
          }
          contents={[
            {
              title: 'Mental health and adolescence',
              references: [
                <ExtLinkNoUnderline href="https://jamanetwork.com/journals/jamapsychiatry/article-abstract/208678">
                  Kessler, R. C., Berglund, P., Demler, O., Jin, R., Merikangas, K. R., & Walters, E. E. (2005).{' '}
                  <b>
                    Lifetime prevalence and age-of-onset distributions of DSM-IV disorders in the National Comorbidity
                    Survey Replication
                  </b>
                  . <i>Archives of general psychiatry, 62</i>(6), 593-602.
                </ExtLinkNoUnderline>,
              ],
            },
            {
              title: 'What factors can contribute to depression and anxiety in teenagers?',
              references: [
                <ExtLinkNoUnderline href="https://psycnet.apa.org/doiLanding?doi=10.1037%2Fbul0000102">
                  Salk, R. H., Hyde, J. S., & Abramson, L. Y. (2017).{' '}
                  <b>
                    Gender differences in depression in representative national samples: Meta-analyses of diagnoses and
                    symptoms
                  </b>
                  . Psychological bulletin, 143(8), 783-822.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://journals.sagepub.com/doi/abs/10.1177/070674370605100206">
                  Somers, J. M., Goldner, E. M., Waraich, P., & Hsu, L. (2006).{' '}
                  <b>Prevalence and incidence studies of anxiety disorders: a systematic review of the literature.</b>{' '}
                  The Canadian Journal of Psychiatry, 51(2), 100-113.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/pii/S0890856718319130">
                  Lawrence, P. J., Murayama, K., & Creswell, C. (2019).{' '}
                  <b>
                    Systematic review and meta-analysis: anxiety and depressive disorders in offspring of parents with
                    anxiety disorders.
                  </b>{' '}
                  Journal of the American Academy of Child & Adolescent Psychiatry, 58(1), 46-60.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://link.springer.com/article/10.1007/s10567-010-0080-1">
                  Goodman, S. H., Rouse, M. H., Connell, A. M., Broth, M. R., Hall, C. M., & Heyward, D. (2011).{' '}
                  <b>Maternal depression and child psychopathology: A meta-analytic review.</b> Clinical child and
                  family psychology review, 14(1), 1-27.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://link.springer.com/article/10.1007/s00787-021-01745-2">
                  Sahle, B. W., Reavley, N. J., Li, W., Morgan, A. J., Yap, M. B. H., Reupert, A., & Jorm, A. F. (2021).{' '}
                  <b>
                    The association between adverse childhood experiences and common mental disorders and suicidality:
                    an umbrella review of systematic reviews and meta-analyses.
                  </b>{' '}
                  <i>European child & adolescent psychiatry</i>, 1-11.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1001349">
                  Norman, R. E., Byambaa, M., De, R., Butchart, A., Scott, J., & Vos, T. (2012).{' '}
                  <b>
                    The long-term health consequences of child physical abuse, emotional abuse, and neglect: a
                    systematic review and meta-analysis.
                  </b>{' '}
                  PLoS medicine, 9(11), e1001349.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/pii/S0890856719321112">
                  LeMoult, J., Humphreys, K. L., Tracy, A., Hoffmeister, J. A., Ip, E., & Gotlib, I. H. (2020).
                  <b>
                    Meta-analysis: exposure to early life stress and risk for depression in childhood and adolescence.
                  </b>
                  <i>Journal of the American Academy of Child & Adolescent Psychiatry, 59</i>(7), 842-855.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://academic.oup.com/jpepsy/article/36/4/375/926006?login=true">
                  Pinquart, M., & Shen, Y. (2011).{' '}
                  <b>
                    Depressive symptoms in children and adolescents with chronic physical illness: an updated
                    meta-analysis.
                  </b>{' '}
                  <i>Journal of pediatric psychology, 36</i>(4), 375-384
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1651-2227.2011.02223.x">
                  Pinquart, M., & Shen, Y. (2011).{' '}
                  <b>
                    Anxiety in children and adolescents with chronic physical illnesses: A meta‐analysis.Acta
                    Paediatrica
                  </b>
                  , 100(8), 1069-1076.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/pii/S0165032713008057">
                  Yap, M. B. H., Pilkington, P. D., Ryan, S. M., & Jorm, A. F. (2014).{' '}
                  <b>
                    Parental factors associated with depression and anxiety in young people: A systematic review and
                    meta-analysis.
                  </b>{' '}
                  <i>Journal of affective disorders</i>, 156, 8-23.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://psycnet.apa.org/record/2017-18450-004">
                  Pinquart, M. (2017).{' '}
                  <strong>
                    Associations of parenting dimensions and styles with externalizing problems of children and
                    adolescents: An updated meta-analysis.
                  </strong>{' '}
                  <i>Developmental psychology, 53</i>(5), 873-932.
                </ExtLinkNoUnderline>,
              ],
            },
            {
              title: 'Self-help strategies for depression',
              references: [
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/abs/pii/S0165032708003418">
                  Morgan, A. J., & Jorm, A. F. (2009).{' '}
                  <b>Self-help strategies that are helpful for sub-threshold depression: a Delphi consensus study.</b>{' '}
                  <i>Journal of Affective Disorders, 115</i>(1-2), 196-200.
                </ExtLinkNoUnderline>,
              ],
            },
            {
              title: 'Self-help strategies for anxiety',
              references: [
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/pii/S0165032716303391">
                  Morgan, A. J., Chittleborough, P., & Jorm, A. F. (2016).{' '}
                  <b>
                    Self-help strategies for sub-threshold anxiety: A Delphi consensus study to find messages suitable
                    for population-wide promotion.
                  </b>{' '}
                  <i>Journal of Affective Disorders, 206</i>, 68-76.
                </ExtLinkNoUnderline>,
              ],
            },

            {
              title: 'What if my teenager doesn’t want to seek help?',
              references: [
                <ExtLinkNoUnderline href="http://www.ncbi.nlm.nih.gov/pubmed/23228570">
                  Yap MB Reavley N, Jorm AF (2013).{' '}
                  <b>
                    Where would young people seek help for mental disorders and what stops them? Findings from an
                    Australian national survey.
                  </b>{' '}
                  Journal of Affective Disorders, 147(1-3):255-61.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://link.springer.com/article/10.1186/s12888-020-02659-0">
                  Aguirre Velasco, A., Cruz, I. S. S., Billings, J., Jimenez, M., & Rowe, S. (2020).{' '}
                  <b>
                    What are the barriers, facilitators and interventions targeting help-seeking behaviours for common
                    mental health problems in adolescents? A systematic review.
                  </b>{' '}
                  <i>BMC psychiatry, 20</i>(1), 1-22.
                </ExtLinkNoUnderline>,
              ],
            },
          ]}
        />
        <TopicAppendix
          title="Breaking the anxiety cycle"
          contents={[
            {
              title: '',
              references: [
                <ExtLinkNoUnderline href="https://pubmed.ncbi.nlm.nih.gov/26644606/">
                  Lawrence, D., Hafekost, J., Johnson, S.E., Saw, S., Buckingham, W.J., Sawyer, M.G., Ainley, J. and
                  Zubrick, S.R., 2016.{' '}
                  <strong>
                    Key findings from the second Australian child and adolescent survey of mental health and wellbeing
                  </strong>
                  . Australian & New Zealand Journal of Psychiatry, 50(9), pp.876-886. doi: 10.1177/0004867415617836
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.oxfordclinicalpsych.com/view/10.1093/med-psych/9780190869984.001.0001/med-9780190869984">
                  Lebowitz, E. R. (2019).{' '}
                  <strong>Addressing parental accommodation when treating anxiety in children.</strong> New York: Oxford
                  University Press.
                </ExtLinkNoUnderline>,
              ],
            },
          ]}
        />
        <TopicAppendix
          title="Good health habits for good mental health"
          contents={[
            {
              title: 'Encourage a nutritious diet',
              summary: (
                <>
                  <Typography paragraph sx={{ marginBottom: 0, marginTop: theme.spacing(1) }}>
                    For information on the Australian Guide to Healthy Eating, click{' '}
                    <ExtLinkNoUnderline href="https://www.eatforhealth.gov.au/sites/default/files/content/The%20Guidelines/n55_agthe_large.pdf">
                      here
                    </ExtLinkNoUnderline>
                    .
                  </Typography>
                  <Typography paragraph sx={{ marginBottom: 0 }}>
                    You can also download a copy of the guide{' '}
                    <ExtLinkNoUnderline href="https://www.eatforhealth.gov.au/guidelines/australian-guide-healthy-eating">
                      here
                    </ExtLinkNoUnderline>
                    .
                  </Typography>
                  <Typography paragraph>
                    For information about recommended serving sizes for teenagers, click{' '}
                    <ExtLinkNoUnderline href="https://www.eatforhealth.gov.au/food-essentials/how-much-do-we-need-each-day">
                      here
                    </ExtLinkNoUnderline>
                    .
                  </Typography>
                </>
              ),
              references: [
                <ExtLinkNoUnderline href="https://dietitiansaustralia.org.au/health-advice/mental-health-including-anxiety-and-depression">
                  <b>Diet and nutrition health advice: Mental health including anxiety and depression.</b> (2022, March
                  9). Dietitians Australia.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.cambridge.org/core/journals/british-journal-of-nutrition/article/effect-of-healthy-dietary-consumption-on-executive-cognitive-functioning-in-children-and-adolescents-a-systematic-review/5B4903E20D46E00D57E29FD7A6BEDB41">
                  Cohen, J. F., Gorski, M. T., Gruber, S. A., Kurdziel, L. B. F., & Rimm, E. B. (2016).{' '}
                  <b>
                    The effect of healthy dietary consumption on executive cognitive functioning in children and
                    adolescents: a systematic review.
                  </b>{' '}
                  <i>British Journal of Nutrition, 116</i>(6), 989-1000.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6484557/">
                  Khanna, P., Chattu, V. K., & Aeri, B. T. (2019).{' '}
                  <b>Nutritional aspects of depression in adolescents-a systematic review.</b>{' '}
                  <i>International Journal of Preventive Medicine, 10</i>, 42.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://ajph.aphapublications.org/doi/abs/10.2105/AJPH.2014.302110">
                  O’neil, A., Quirk, S. E., Housden, S., Brennan, S. L., Williams, L. J., Pasco, J. A., ... & Jacka, F.
                  N. (2014).{' '}
                  <b>Relationship between diet and mental health in children and adolescents: a systematic review.</b>{' '}
                  <i>American journal of public health, 104</i>(10), e31-e42.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://academic.oup.com/nutritionreviews/article/72/4/267/1859597?login=true">
                  Prado, E. L., & Dewey, K. G. (2014). <b>Nutrition and brain development in early life.</b>{' '}
                  <i>Nutrition reviews, 72</i>(4), 267-284.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.eatforhealth.gov.au/sites/default/files/content/n55_australian_dietary_guidelines.pdf">
                  National Health and Medical Research Council (2013) <b>Australian Dietary Guidelines.</b>
                  Canberra: National Health and Medical Research Council.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.eatforhealth.gov.au/eating-well/healthy-eating-throughout-all-life/healthy-eating-infants-children-and-teenagers">
                  <b>Healthy eating for infants, children and teenagers.</b> (2019, June 4). National Health and Medical
                  Research Council, Canberra.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://raisingchildren.net.au/teens/healthy-lifestyle/daily-food-guides/nutrition-healthy-food-teens">
                  <b>Nutrition and healthy food for teenagers.</b> (2021, January 22). Raising Children Network.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.mdpi.com/article/10.3390/nu14132568">
                  Lane, M. M., Gamage, E., Travica, N., Dissanayaka, T., Ashtree, D. N., Gauci, S., ... & Marx, W.
                  (2022).{' '}
                  <b>
                    Ultra-Processed Food Consumption and Mental Health: A Systematic Review and Meta-Analysis of
                    Observational Studies.
                  </b>{' '}
                  <i>Nutrients, 14</i>(13), 2568.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://dietitiansaustralia.org.au/health-advice/intermittent-fasting">
                  <b>Diet and nutrition health advice: Intermittent fasting</b> (2022, May 10). Dietitians Australia.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://psycnet.apa.org/buy/2016-47863-001">
                  Stice, E., Gau, J. M., Rohde, P., & Shaw, H. (2017).{' '}
                  <b>
                    Risk factors that predict future onset of each DSM–5 eating disorder: Predictive specificity in
                    high-risk adolescent females.
                  </b>{' '}
                  <i>Journal of abnormal psychology, 126</i>(1), 38.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/pii/S0002822306000046">
                  Neumark-Sztainer, D., Wall, M., Guo, J., Story, M., Haines, J., & Eisenberg, M. (2006).{' '}
                  <b>
                    Obesity, disordered eating, and eating disorders in a longitudinal study of adolescents: how do
                    dieters fare 5 years later?.
                  </b>{' '}
                  <i>Journal of the American Dietetic Association, 106</i>(4), 559-568.
                </ExtLinkNoUnderline>,
              ],
            },
            {
              title: 'How can I support my teen to eat well?',
              references: [
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/pii/S019566631400021X">
                  Dickens, E., & Ogden, J. (2014).{' '}
                  <b>
                    The role of parental control and modelling in predicting a child’s diet and relationship with food
                    after they leave home.
                  </b>{' '}
                  A prospective study. <i>Appetite, 76</i>, 23-29.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/pii/S0002822306000046">
                  Neumark-Sztainer, D., Wall, M., Guo, J., Story, M., Haines, J., & Eisenberg, M. (2006).{' '}
                  <b>
                    Obesity, disordered eating, and eating disorders in a longitudinal study of adolescents: how do
                    dieters fare 5 years later?.
                  </b>{' '}
                  <i>Journal of the American Dietetic Association, 106</i>(4), 559-568.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://onlinelibrary.wiley.com/doi/abs/10.1002/eat.22189">
                  Loth, K. A., MacLehose, R. F., Fulkerson, J. A., Crow, S., & Neumark‐Sztainer, D. (2014).{' '}
                  <b>
                    Are food restriction and pressure‐to‐eat parenting practices associated with adolescent disordered
                    eating behaviors?.
                  </b>{' '}
                  <i>International Journal of Eating Disorders, 47</i>(3), 310-314.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://onlinelibrary.wiley.com/doi/abs/10.1002/anzf.1005">
                  Godfrey, K., Rhodes, P., & Hunt, C. (2013).{' '}
                  <b>
                    The relationship between family mealtime interactions and eating disorder in childhood and
                    adolescence: A systematic review.
                  </b>{' '}
                  <i>Australian and New Zealand Journal of Family Therapy, 34</i>(1), 54-74.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/pii/S0002822302000846">
                  Neumark-Sztainer, D., Hannan, P. J., Story, M., Croll, J., & Perry, C. (2003).{' '}
                  <b>
                    Family meal patterns: associations with sociodemographic characteristics and improved dietary intake
                    among adolescents.
                  </b>
                  <i>Journal of the american dietetic association, 103</i>(3), 317-322.
                </ExtLinkNoUnderline>,
              ],
            },
            {
              title: 'Encourage physical activity',
              summary: (
                <Typography paragraph sx={{ marginTop: theme.spacing(1) }}>
                  <ExtLinkNoUnderline href="https://www.health.gov.au/sites/default/files/documents/2021/05/24-hour-movement-guidelines-children-and-young-people-5-to-17-years-fact-sheet.pdf">
                    Click here
                  </ExtLinkNoUnderline>{' '}
                  to see the physical activity guidelines for children and young people.
                </Typography>
              ),
              references: [
                <ExtLinkNoUnderline href="http://www.ncbi.nlm.nih.gov/pubmed/23648221">
                  Kremer, P., Elshaug, C., Leslie, E., Toumbourou, J. W., Patton, G. C., & Williams, J. (2014).{' '}
                  <b>Physical activity, leisure-time screen use and depression among children and young adolescents</b>{' '}
                  Journal of Science and Medicine in Sport, 17(2), 183-187.
                </ExtLinkNoUnderline>,
              ],
            },
            {
              title: 'Encourage healthy screen use',
              summary: (
                <Typography paragraph sx={{ marginTop: theme.spacing(1) }}>
                  <ExtLinkNoUnderline href="https://www.internetmatters.org/issues/screen-time/?gclid=EAIaIQobChMI9oSvr-Ox5QIVDLTtCh35gwTgEAAYASAAEgL_8fD_BwE">
                    Click here
                  </ExtLinkNoUnderline>{' '}
                  for more information and tips for managing screen time.
                </Typography>
              ),
            },
            {
              title: 'Encourage good sleep habits',
              references: [
                <ExtLinkNoUnderline href="http://www.ncbi.nlm.nih.gov/pubmed/23415826">
                  Lopresti, A. L., Hood, S. D., & Drummond, P. D. (2013).{' '}
                  <b>
                    A review of lifestyle factors that contribute to important pathways associated with major
                    depression: Diet, sleep and exercise.
                  </b>{' '}
                  Journal of Affective Disorders, 148(1), 12-27.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="http://www.ncbi.nlm.nih.gov/pubmed/21300408">
                  Baglioni, C., Battagliese, G., Feige, B., Spiegelhalder, K., Nissen, C., Voderholzer, U., Lombardo,
                  C., & Riemann, D. (2011).{' '}
                  <b>
                    Insomnia as a predictor of depression: a meta-analytic evaluation of longitudinal epidemiological
                    studies.
                  </b>{' '}
                  Journal of Affective Disorders, 135(1-3), 10-19.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/pii/S1087079218301138">
                  Hertenstein, E., Feige, B., Gmeiner, T., Kienzler, C., Spiegelhalder, K., Johann, A., ... & Baglioni,
                  C. (2019). <b>Insomnia as a predictor of mental disorders: a systematic review and meta-analysis.</b>{' '}
                  <i>Sleep medicine reviews, 43</i>, 96-105.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/abs/pii/S108707922030054X">
                  Short, M. A., Booth, S. A., Omar, O., Ostlundh, L., & Arora, T. (2020).{' '}
                  <b>
                    The relationship between sleep duration and mood in adolescents: A systematic review and
                    meta-analysis.
                  </b>{' '}
                  <i>Sleep medicine reviews, 52</i>, 101311.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="http://vuir.vu.edu.au/467/1/teenagesleep.pdf">
                  Bruck, D. (2006). <b>Teenage Sleep: Understanding and helping the sleep of 12-20 year olds.</b>{' '}
                  Wellness Promotion Unit, Victoria University, Melbourne, Australia.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://onlinelibrary.wiley.com/doi/abs/10.1111/jsr.12484">
                  Reddy, R., Palmer, C. A., Jackson, C., Farris, S. G., & Alfano, C. A. (2017).{' '}
                  <b>
                    Impact of sleep restriction versus idealized sleep on emotional experience, reactivity and
                    regulation in healthy adolescents.
                  </b>{' '}
                  <i>Journal of sleep research, 26</i>(4), 516-525.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://www.betterhealth.vic.gov.au/health/healthyliving/teenagers-and-sleep">
                  <b>Teenagers and Sleep.</b> (2015, April 11). Department of Health, State Government of Victoria,
                  Australia.
                </ExtLinkNoUnderline>,
              ],
            },
            {
              title: 'Discourage any alcohol and other drug use',
              summary: (
                <>
                  <Typography paragraph>Parenting strategies: Preventing adolescent alcohol misuse</Typography>
                  <Typography paragraph>
                    You can find out more about parenting strategies to prevent alcohol misuse in teenagers{' '}
                    <ExtLinkNoUnderline href="https://www.parentingstrategies.net/">here</ExtLinkNoUnderline>.
                  </Typography>
                </>
              ),
              references: [
                <ExtLinkNoUnderline href="http://www.ncbi.nlm.nih.gov/pubmed/21806515">
                  McKenzie, M., Jorm, A. F., Romaniuk, H., Olsson, C. A., Jorm, A. F., Romaniuk, H., & Patton, G. C.
                  (2011).{' '}
                  <b>
                    Association of adolescent symptoms of depression and anxiety with alcohol use disorders in young
                    adulthood: Findings from the Victorian Adolescent Health Cohort Study.
                  </b>{' '}
                  Medical Journal of Australia, 195, S27-30.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="http://www.ncbi.nlm.nih.gov/pubmed/20707783">
                  McKenzie, M., Olsson, C. A., Jorm, A. F., Romaniuk, H., & Patton, G. C. (2010).{' '}
                  <b>
                    Association of adolescent symptoms of depression and anxiety with daily smoking and nicotine
                    dependence in young adulthood: Findings from a 10-year longitudinal study.
                  </b>{' '}
                  Addiction, 105 (9), 1652-1569.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://pubmed.ncbi.nlm.nih.gov/34384705/">
                  Gorfinkel, L., Hasin, D., Miech, R., Keyes, K. M. (2022).{' '}
                  <b>The Link Between Depressive Symptoms and Vaping Nicotine in U.S. Adolescents.</b> 2017–2019.
                  Journal of Adolescent Health, 70 (1), 133-139.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://journals.sagepub.com/doi/10.1080/00048670701634986">
                  Baker, K. D., Lubman, D. I., Cosgrave, E. M., Killackey, E. J., Yuen, H. P., Hides, L., Baksheev,
                  G.N., Buckby, J.A., & Yung, A. R. (2007).{' '}
                  <b>
                    Impact of co-occurring substance use on 6 month outcomes for young people seeking mental health
                    treatment.
                  </b>{' '}
                  Australia New Zealand Journal of Psychiatry, 41 (11), 896-902.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://journals.sagepub.com/doi/abs/10.1177/155005940904000110">
                  Squeglia, L. M., Jacobus, J., & Tapert, S. F. (2009).{' '}
                  <b>The influence of substance use on adolescent brain development.</b>{' '}
                  <i>Clinical EEG and neuroscience, 40</i>(1), 31-38.
                </ExtLinkNoUnderline>,
                <ExtLinkNoUnderline href="https://link.springer.com/article/10.1007/s11065-019-09411-w">
                  Lees, B., Mewton, L., Stapinski, L. A., Squeglia, L. M., Rae, C. D., & Teesson, M. (2019).
                  <b>
                    Neurobiological and cognitive profile of young binge drinkers: A systematic review and
                    meta-analysis.
                  </b>
                  <i>Neuropsychology review, 29</i>(3), 357-385.
                </ExtLinkNoUnderline>,
              ],
            },
          ]}
        />
        <TopicAppendix
          title="Partners in problem solving"
          contents={[]}
          summary={
            <>
              <Typography variant="h5" align="left" sx={titleStyle}>
                Stress management source:
              </Typography>
              <Typography paragraph>
                <ExtLinkNoUnderline href="https://www.jhsph.edu/research/centers-and-institutes/center-for-adolescent-health/_docs/TTYE-Guide.pdf">
                  McNeely, C. & Blanchard, J. (2009).
                  <b>The Teen Years Explained: A Guide to Healthy Adolescent Development.</b> Baltimore: Center for
                  Adolescent Health. Johns Hopkins Bloomberg School of Public Health.
                </ExtLinkNoUnderline>
              </Typography>
              <BullyingSection titleStyle={titleStyle} />
            </>
          }
        />
        <TopicAppendix
          title="Nurture roots & inspire wings"
          contents={[]}
          summary={
            <Typography component="span" paragraph>
              Reference:
              <ol>
                <li>
                  <ExtLinkNoUnderline href="http://www.ncbi.nlm.nih.gov/pubmed/24308895">
                    Yap, M. B. H., Pilkington, P. D., Ryan, S. M., & Jorm, A. F. (2014).{' '}
                    <b>
                      Parental factors associated with depression and anxiety in young people: A systematic review and
                      meta-analysis.{' '}
                    </b>
                    Journal of Affective Disorders, 156, 8-23.
                  </ExtLinkNoUnderline>
                </li>
              </ol>
            </Typography>
          }
        />
        <TopicAppendix
          title="Good friends = supportive relationships"
          contents={[]}
          summary={<BullyingSection titleStyle={titleStyle} />}
        />
        <TopicAppendix
          title="Maintaining the gains"
          summary={
            <>
              <Typography variant="h5" align="left" sx={titleStyle}>
                Mental Health First Aid (MHFA) guidelines on depression
              </Typography>
              <Typography paragraph>
                For more information on what to do to help someone with depression,{' '}
                <ExtLinkNoUnderline href="https://mhfa.com.au/sites/default/files/depression_-_mhfa_guidelines_2021_0.pdf">
                  download the MHFA guidelines on depression.
                </ExtLinkNoUnderline>
              </Typography>
              <Typography variant="h5" align="left" sx={titleStyle}>
                Mental Health First Aid guidelines on clinical anxiety
              </Typography>
              <Typography component="span" paragraph>
                For more information on what to do to help someone with clinical anxiety, download the MHFA guidelines
                on traumatic events and panic attacks:
                <ul>
                  <li>
                    <ExtLinkNoUnderline href="https://mhfa.com.au/sites/default/files/traumatic_events_children_-_mhfa_guidelines_2021_0.pdf">
                      Mental Health First Aid Guidelines on helping a child after a traumatic event.
                    </ExtLinkNoUnderline>
                  </li>
                  <li>
                    <ExtLinkNoUnderline href="https://mhfa.com.au/sites/default/files/panic_attacks_-_mhfa_-_guidelines_-_2021_.pdf">
                      Mental Health First Aid guidelines on panic attacks.
                    </ExtLinkNoUnderline>
                  </li>
                </ul>
              </Typography>
            </>
          }
          contents={[
            {
              title: 'Self-help strategies for depression',
              references: [
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/pii/S0165032708003418">
                  Morgan, A. J., & Jorm, A. F. (2009).{' '}
                  <b>Self-help strategies that are helpful for sub-threshold depression: a Delphi consensus study.</b>{' '}
                  <i>Journal of Affective Disorders, 115</i>(1-2), 196-200.
                </ExtLinkNoUnderline>,
              ],
            },
            {
              title: 'Self-help strategies for anxiety',
              references: [
                <ExtLinkNoUnderline href="https://www.sciencedirect.com/science/article/pii/S0165032716303391">
                  Morgan, A. J., Chittleborough, P., & Jorm, A. F. (2016).{' '}
                  <b>
                    Self-help strategies for sub-threshold anxiety: A Delphi consensus study to find messages suitable
                    for population-wide promotion.
                  </b>{' '}
                  <i>Journal of Affective Disorders, 206</i>, 68-76.
                </ExtLinkNoUnderline>,
              ],
            },
            {
              title: "But what if my teenager doesn't want to seek help?",
              references: [
                <ExtLinkNoUnderline href="http://www.ncbi.nlm.nih.gov/pubmed/23228570">
                  Yap MB Reavley N, Jorm AF (2013).{' '}
                  <b>
                    Where would young people seek help for mental disorders and what stops them? Findings from an
                    Australian national survey.
                  </b>{' '}
                  Journal of Affective Disorders, 147(1-3):255-61.
                </ExtLinkNoUnderline>,
              ],
            },
          ]}
        />
      </Box>
    </Container>
  );
}
